import { Box, Heading } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchTokenDataFun } from "./action"

export const LandingPage: React.FC = () => {
    const navigate = useNavigate()
    const { token } = useParams()
    const [error, setError] = useState(false)
    useEffect(() => {
        (async () => {
            sessionStorage.setItem('roomToken', token ? token : "")
            let decryptedData = await fetchTokenDataFun()
            if (decryptedData) {
                if (decryptedData.role === "patient") {
                    sessionStorage.setItem("name", "patient")
                }
                sessionStorage.setItem("roomId", decryptedData.roomId)
                sessionStorage.setItem("beneficiaryId", decryptedData.beneficiaryId)
                sessionStorage.setItem("medicalInfoUrl", decryptedData.medicalInfoUrl)
                sessionStorage.setItem("uploadPrescriptionUrl", decryptedData.uploadPrescriptionUrl)
                if (decryptedData.serviceName === "100ms") {
                    navigate(`/100ms/preview/${decryptedData.roomId}/host`)
                } else if (decryptedData.serviceName === "azure") {
                    navigate(`/azure/${decryptedData.roomId}`)
                }
            } else {
                sessionStorage.removeItem("name")
                sessionStorage.removeItem("roomId")
                sessionStorage.removeItem("beneficiaryId")
                sessionStorage.removeItem("medicalInfoUrl")
                sessionStorage.removeItem("uploadPrescriptionUrl")
                setError(true)
            }
        })();
        // eslint-disable-next-line
    }, [])

    return (
        <Box>
            <Heading textAlign={"center"}>
                Welcome to Doctor Consult
            </Heading>
            {error ?
                <Heading size={"md"} textAlign={"center"} mt={"200px"}>Oops! Something went wrong. Please try again later.</Heading> : null
            }
        </Box>
    )
}   