import { Box } from '@chakra-ui/react';
import './App.css';
import { MainRoutes } from './Routes/MainRoutes';

function App() {
  return (

    <Box height={"100vh"}>
      <MainRoutes />
    </Box>

  );
}

export default App;
