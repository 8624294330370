import { useEffect, useRef, useState } from 'react';
import { Stack, Image, ChoiceGroup, IChoiceGroupOption, Text, TextField } from '@fluentui/react';
import heroSVG from '../assets/hero.svg';
import {
  imgStyle,
  infoContainerStyle,
  callContainerStackTokens,
  callOptionsGroupStyles,
  configContainerStyle,
  configContainerStackTokens,
  containerStyle,
  containerTokens,
  headerStyle,
  teamsItemStyle,
  buttonStyle
} from '../styles/HomeScreen.styles';
import { ThemeSelector } from '../theming/ThemeSelector';
import { localStorageAvailable } from '../utils/localStorage';
import { getDisplayNameFromLocalStorage, saveDisplayNameToLocalStorage } from '../utils/localStorage';
import { DisplayNameField } from './DisplayNameField';
import { ParticipantRole, RoomLocator, TeamsMeetingLinkLocator } from '@azure/communication-calling';
import { CallAdapterLocator } from '@azure/communication-react';
import { useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

export interface HomeScreenProps {
  startCallHandler(callDetails: {
    displayName: string;
    callLocator?: CallAdapterLocator | TeamsMeetingLinkLocator;
    option?: string;
    role?: ParticipantRole;
  }): void;
  joiningExistingCall: boolean;
  getUserToken: () => Promise<any>;
}

export const HomeScreen = (props: HomeScreenProps): JSX.Element => {
  const imageProps = { src: heroSVG.toString() };
  const headerTitle = props.joiningExistingCall ? 'Join Call' : 'Start or join a call';
  const callOptionsGroupLabel = 'Select a call option';
  const buttonText = 'Next';
  const callOptions: IChoiceGroupOption[] = [
    { key: 'Rooms', text: 'Join a Rooms Call' }
  ];

  const roomIdLabel = 'Room ID';
  const roomRoleOptions: IChoiceGroupOption[] = [
    { key: 'Presenter', text: 'Presenter' }
  ];

  const defaultDisplayName = localStorageAvailable ? getDisplayNameFromLocalStorage() : null;
  const [displayName, setDisplayName] = useState<string | undefined>(defaultDisplayName ?? undefined);
  const [chosenCallOption, setChosenCallOption] = useState<IChoiceGroupOption>(callOptions[0]);
  const [callLocator, setCallLocator] = useState<RoomLocator>();
  // eslint-disable-next-line
  const [chosenRoomsRoleOption, setRoomsRoleOption] = useState<IChoiceGroupOption>(roomRoleOptions[0]);
  const startGroupCall: boolean = chosenCallOption.key === 'ACSCall';
  const teamsCallChosen: boolean = chosenCallOption.key === 'TeamsMeeting';
  const buttonEnabled = displayName && (startGroupCall || (teamsCallChosen && callLocator) || chosenRoomsRoleOption) && callLocator;
  const showDisplayNameField = true;
  let btnRef = useRef<HTMLButtonElement>(null)
  const { roomId } = useParams()

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line
      await props.getUserToken()
      if (sessionStorage.getItem("name") === "patient") {
        setDisplayName("patient")
      }
      setCallLocator(roomId ? { roomId: roomId } : undefined)
    })();
    // eslint-disable-next-line
  }, [roomId])


  useEffect(() => {
    if (callLocator?.roomId && displayName === "patient") {
      btnRef.current?.click();
    }
  }, [displayName, callLocator]);

  return (
    <Stack
      horizontal
      wrap
      horizontalAlign="center"
      verticalAlign="center"
      tokens={containerTokens}
      className={containerStyle}
    >
      <Image alt="Welcome to the ACS Calling sample app" className={imgStyle} {...imageProps} />
      <Stack className={infoContainerStyle}>
        <Text role={'heading'} aria-level={1} className={headerStyle}>
          {headerTitle}
        </Text>
        <Stack className={configContainerStyle} tokens={configContainerStackTokens}>
          <Stack tokens={callContainerStackTokens}>
            {!props.joiningExistingCall && (
              <ChoiceGroup
                styles={callOptionsGroupStyles}
                label={callOptionsGroupLabel}
                defaultSelectedKey="Rooms"
                options={callOptions}
                required
                onChange={(_, option) => option && setChosenCallOption(option)}
              />
            )}

            {chosenCallOption.key === 'Rooms' && (
              <Stack>
                <TextField
                  className={teamsItemStyle}
                  label={roomIdLabel}
                  required
                  value={callLocator ? callLocator.roomId : ""}
                  placeholder={'Enter a room ID'}
                  onChange={(_, newValue) => setCallLocator(newValue ? { roomId: newValue } : undefined)}
                />
              </Stack>
            )}

          </Stack>
          {showDisplayNameField && <DisplayNameField defaultName={displayName} setName={setDisplayName} />}
          <Button
            disabled={!buttonEnabled}
            className={buttonStyle}
            // text={buttonText}
            ref={btnRef}
            onClick={() => {
              if (displayName) {
                displayName && saveDisplayNameToLocalStorage(displayName);

                props.startCallHandler({
                  //TODO: This needs to be updated after we change arg types of TeamsCall
                  displayName: !displayName ? 'Teams UserName PlaceHolder' : displayName,
                  callLocator: callLocator,
                  option: chosenCallOption.key,
                  role: chosenRoomsRoleOption.key as ParticipantRole
                });
              }
            }}
          >{buttonText}</Button>

          <div>
            <ThemeSelector label="Theme" horizontal={true} />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};
