import { Route, Routes } from "react-router-dom"
import { NotFoundPage } from "../Pages/NotFoundPage/NotFoundPage"
import { AzureVideo } from "../Pages/azure/AzureVideo"
import HundredMs from "../Pages/100ms/100ms"
import { LandingPage } from "../Pages/LandingPage/LandingPage"




export const MainRoutes = () => {
    return (
        <Routes>
            <Route path="azure/:roomId" element={<AzureVideo />} />
            <Route path="100ms/*" element={< HundredMs />} />
            <Route path="/:token" element={<LandingPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}