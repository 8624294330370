import { ParticipantRole } from "@azure/communication-calling"
import { addUserToRoom, fetchTokenData, getAzureSignedUrl, getUserToken } from "../../../Http/Index"


export const fetchTokenDataFun = async () => {
    try {
        let response = await fetchTokenData()
        console.log(response)
        return response.data
    } catch (error: any) {
        console.log(error)
        alert(error.response?.data?.message || "Some issue while getting token data")
    }

}

export const getUserTokenFun = async (): Promise<any> => {
    try {
        const response = await getUserToken({ scope: "chat,voip" }, "azure")
        return response.data
    } catch (error: any) {
        console.log(error)
        alert('Invalid token response');
    }
};


export const addUserToRoomFun = async (userId: string, roomId: string, role: ParticipantRole): Promise<any> => {

    try {
        const response = await addUserToRoom({ userId: userId, roomId: roomId, role: role })
        return response.data
    } catch (error: any) {
        console.log(error)
        alert(error?.response?.data?.message || "Unable to add user to the room");
        return true
    }
};

export const uploadPrescriptionFun = async (url: string, docName: string, benId: string, docType: string) => {
    try {
        const response = await getAzureSignedUrl(url, docName, benId, docType)
        return response.data
    } catch (error) {
        alert(error)
    }
}