import { fetchTokenData } from "../../Http/Index"


export const fetchTokenDataFun = async () => {
    try {
        let response = await fetchTokenData()
        console.log(response)
        return response.data
    } catch (error: any) {
        console.log(error)
        alert(error.response?.data?.message || "Some issue while getting token data")
    }

}
