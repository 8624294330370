import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const NotFoundPage:React.FC = () => {
    return (
        <Box textAlign="center">
            <Heading as="h1" size="xl" mt={8} >
                Page Not Found !
            </Heading>
            <Text mt={4}>Sorry, the page you are looking for does not exist.</Text>
        </Box>
    );
};