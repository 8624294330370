import axios from "axios";
import apiConfig from "../ApiConfig/apiConfig";

const baseUri = apiConfig.api.baseURL;
// const baseUri = 'http://localhost:3005'
const urls = {
  VIDEO_URI: `${baseUri}/video/api/v1`,
  //  VIDEO_URI: `${baseUri}/api/v1`,
};

const axiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",

  },
});

export const fetchTokenData = () => axiosInstance.post(`${urls.VIDEO_URI}/video-service/verify-rooms-token`, { roomToken: sessionStorage.getItem("roomToken") })
export const getUserToken = (payload: Object, service: string) => axiosInstance.post(`${urls.VIDEO_URI}/video-service/get-user-token/${service}`, payload)
export const addUserToRoom = (payload: Object) => axiosInstance.post(`${urls.VIDEO_URI}/video-service/add-user-to-room/azure`, payload)
export const getAzureSignedUrl = (
  url: string,
  docName: string,
  benId: string,
  docType: string
) =>
  axiosInstance.post(`${url}`, {
    docName,
    benId,
    docType,
  });
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     // console.log(originalRequest,'originalRequest ****************************');
//     // console.log(error,'***********');
//     // Handle unauthorized errors (status 401)
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;
//       try {
//         // Attempt to refresh the access token using a POST request to refresh endpoint
//         await axios.post(
//           `${urls.User_URI}auth/poco-user-refresh-token`,
//           {},
//           {
//             withCredentials: true, // Ensure credentials are sent with the refresh token request
//           }
//         );
//         // Retry the original request
//         return axiosInstance(originalRequest);
//       } catch (err) {
//         // Handle errors that occur during the refresh token request
//         // console.log('err:', err);
//         localStorage.setItem("isLogin", "false");
//         // window.location.reload();
//         // const localStorageUpdatedEvent = new Event("localStorageUpdated");
//         // window.dispatchEvent(localStorageUpdatedEvent);
//         // Redirect to login page, show an error message, or handle as appropriate
//         // throw err; // Throw the error to indicate that the request failed
//       }
//       localStorage.setItem("isLogin", "false");
//       // window.location.reload();
//     }
//     // For other errors, just throw the error to be handled by the caller
//     throw error;
//   }
// );

export default axiosInstance;
