// import { Button, Flex, Heading, Input } from "@chakra-ui/react";
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { Spinner } from '@fluentui/react';
import { CallAdapterLocator } from '@azure/communication-react';
import React, { useEffect, useState } from 'react';
import {
    createGroupId,
    getRoomIdFromUrl,
    isLandscape,
    isOnIphoneAndNotSafari,
    navigateToHomePage,
    WEB_APP_TITLE
} from './utils/AppUtils';
// import style from "./azureVideo.module.css"

import { useIsMobile } from './utils/useIsMobile';
import { useSecondaryInstanceCheck } from './utils/useSecondaryInstanceCheck';
import { CallError } from './views/CallError';
import { CallScreen } from './views/CallScreen';
import { HomeScreen } from './views/HomeScreen';
import { PageOpenInAnotherTab } from './views/PageOpenInAnotherTab';
import { UnsupportedBrowserPage } from './views/UnsupportedBrowserPage';
import { addUserToRoomFun, getUserTokenFun } from "./action/action";
type AppPages = 'home' | 'call';

export const AzureVideo: React.FC = () => {
    const [page, setPage] = useState<AppPages>('home');
    // const [prescriptionState, setPrescriptionState] = useState<any>(null)
    // User credentials to join a call with - these are retrieved from the server
    const [token, setToken] = useState<string>();
    const [userId, setUserId] = useState<CommunicationUserIdentifier>();
    const [userCredentialFetchError, setUserCredentialFetchError] = useState<boolean>(false);

    // Call details to join a call - these are collected from the user on the home screen
    const [callLocator, setCallLocator] = useState<CallAdapterLocator>(createGroupId());
    const [displayName, setDisplayName] = useState<string>('');


    const getUserToken = async () => {
        try {
            const { token, user } = await getUserTokenFun();
            setToken(token);
            setUserId(user);
            return true
        } catch (e) {
            console.error(e);
            setUserCredentialFetchError(true);
        }
    }

    const isMobileSession = useIsMobile();
    const isLandscapeSession = isLandscape();
    const isAppAlreadyRunningInAnotherTab = useSecondaryInstanceCheck();

    useEffect(() => {
        if (isMobileSession && isLandscapeSession) {
            console.log('ACS Calling sample: Mobile landscape view is experimental behavior');
        }
    }, [isMobileSession, isLandscapeSession]);

    if (isMobileSession && isAppAlreadyRunningInAnotherTab) {
        return <PageOpenInAnotherTab />;
    }

    const supportedBrowser = !isOnIphoneAndNotSafari();
    if (!supportedBrowser) {
        return <UnsupportedBrowserPage />;
    }

    // const handleUploadPrescription = async (file: any) => {
    //     if (prescriptionState) {
    //         let benId = sessionStorage.getItem("beneficiaryId") || ""
    //         let url = sessionStorage.getItem("uploadPrescriptionUrl") || ""
    //         let response = await uploadPrescriptionFun(url, prescriptionState.name, benId, prescriptionState.type)
    //         console.log(response)
    //     } else {
    //         alert("Please select file")
    //     }
    // }

    // const handlePrescriptionState = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setPrescriptionState(e.target?.files?.[0])
    // }

    switch (page) {
        case 'home': {
            document.title = `home - ${WEB_APP_TITLE}`;
            const joiningExistingCall: boolean = !!getRoomIdFromUrl();

            return (
                <HomeScreen
                    joiningExistingCall={joiningExistingCall}
                    startCallHandler={async (callDetails) => {
                        setDisplayName(callDetails.displayName);

                        let callLocator: CallAdapterLocator | undefined =
                            callDetails.callLocator;

                        callLocator = callLocator || getRoomIdFromUrl();

                        callLocator = callLocator || createGroupId();

                        if ('roomId' in callLocator) {
                            if (userId && 'communicationUserId' in userId) {
                                const hasGotError = await addUserToRoomFun(userId.communicationUserId, callLocator.roomId, callDetails.role ?? 'Presenter');
                              
                                if (hasGotError) {
                                    return
                                }
                            } else {
                                alert('Invalid userId!');
                            }
                        }

                        setCallLocator(callLocator);

                        // Update window URL to have a joinable link
                        // if (!joiningExistingCall) {
                        //     window.history.pushState({}, document.title, window.location.origin + getJoinParams(callLocator));
                        //     console.log(window.history.pushState({}, document.title, window.location.origin + getJoinParams(callLocator)))
                        // }

                        setPage('call');

                    }}
                    getUserToken={getUserToken}
                />
            );
        }

        case 'call': {
            if (userCredentialFetchError) {
                document.title = `error - ${WEB_APP_TITLE}`;
                return (
                    <CallError
                        title="Error getting user credentials from server"
                        reason="Ensure the sample server is running."
                        rejoinHandler={() => setPage('call')}
                        homeHandler={navigateToHomePage}
                    />
                );
            }

            if (!token || !userId || !displayName || !callLocator) {
                document.title = `credentials - ${WEB_APP_TITLE}`;
                return <Spinner label={'Getting user credentials from server'} ariaLive="assertive" labelPosition="top" />;
            }
            return (
                <>
                    <CallScreen token={token} userId={userId} displayName={displayName} callLocator={callLocator} />
                    {/* <Flex className={style.custom} ml={"10px"} flexWrap={"wrap"} rowGap={"30px"} columnGap={"5%"} justifyContent={"center"} alignItems={"center"}>
                        <Flex flexWrap={"wrap"} alignItems={"center"} rowGap={"10px"}>
                            <Heading size={"m"} >Prescription : </Heading>
                            <Flex flexWrap={"wrap"} alignItems={"center"} rowGap={"10px"}>
                                <Input type='file' onChange={handlePrescriptionState} maxWidth={"fit-content"}></Input>
                                <Flex gap={"10px"} alignItems={"center"} >
                                    <Button onClick={handleUploadPrescription} colorScheme="green">Upload</Button>
                                    <Button colorScheme="green">Download</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex gap={"10px"} alignItems={"center"} justifyContent={"left"} >
                            <Heading size={"m"}>Medical Info : </Heading>
                            <Button colorScheme="green">Download</Button>
                        </Flex>
                    </Flex> */}
                </>
            );
        }
        default:
            document.title = `error - ${WEB_APP_TITLE}`;
            return <>Invalid page</>;
    }

}

// const getJoinParams = (locator: CallAdapterLocator): string => {
//     if ('meetingLink' in locator) {
//         return '?teamsLink=' + encodeURIComponent(locator.meetingLink);
//     }
//     if ('roomId' in locator) {
//         return '?roomId=' + encodeURIComponent(locator.roomId);
//     }
//     return '?groupId=' + encodeURIComponent(locator.groupId);
// };